<template>
  <div class="innovate-box">
    <!--  引入头部-->
    <Head carouseType="INNOVATE"></Head>
    <div class="innovate-body">
      <div class="innovate-body-title">
        <div class="title">{{ $store.state.staticName.innovation }}</div>
        <div class="xian"></div>
      </div>
      <div class="innovate-detail-list">
        <div
          class="detail-item"
          v-for="(item, index) in innovateList"
          :key="'inn' + index"
          :style="index % 2 === 1 ? 'background: #fafafa;' : ''"
        >
          <!--          左边文字描述-->
          <div class="text-box-left" v-if="index % 2 === 1">
            <div class="detail-title">
              {{ item.title }}
            </div>
            <div class="detail-text">
              {{ item.text }}
            </div>
          </div>
          <div
            class="img-box"
            :style="index % 2 === 1 ? 'margin-left: 38px' : ''"
          >
            <img :src="item.abbreviation[0]" />
          </div>
          <!--          右边文字描述-->
          <div class="text-box-right" v-if="index % 2 !== 1">
            <div class="detail-title">
              {{ item.title }}
            </div>
            <div class="detail-text">
              {{ item.text }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--  引入脚部-->
    <Foot></Foot>
  </div>
</template>

<script>
import Head from "@/components/Head";
import Foot from "@/components/Foot";
import { mapState } from "vuex";
export default {
  name: "innovate",
  components: { Foot, Head },
  data() {
    return {
      // 技术创新数组
      innovateList: [],
    };
  },
  created() {
    // 查询数据
    this.queryInnovate();
  },
  watch: {
    "$store.state.languageType": {
      handler(newVal) {
        this.queryInnovate();
      },
      // deep: true // 深度监听
    },
  },
  computed: {
    ...mapState({
      languageType: (state) => state.languageType, // 语言类型
    }),
  },
  methods: {
    queryInnovate() {
      this.$axios({
        url: "/queryPRO_SkillAll",
        method: "GET",
        params: {
          type: this.languageType,
        },
      }).then((res) => {
        this.innovateList = res.data.value;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.innovate-box {
  background: #ffffff;
  .innovate-body {
    .innovate-body-title {
      margin: {
        top: 74px;
        left: 130px;
        right: 130px;
      }
      width: 1660px;
      height: 90px;
      .title {
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 1660px;
        height: 70px;
        font-size: 50px;
        font-weight: 400;
        text-align: center;
        color: #4d4d4d;
      }
      .xian {
        margin-top: 8px;
        border: 1px solid #808080;
      }
    }
    .innovate-detail-list {
      min-height: 565px;
      .detail-item {
        width: 1920px;
        height: 565px;
        background: #e6e6e6;
        display: flex;
        margin-bottom: 105px;
        .img-box {
          margin-left: 130px;
          width: 793px;
          height: 565px;
          background: rgba(0, 0, 0, 0.1);
          img {
            width: 793px;
            height: 565px;
          }
        }
        .text-box-right {
          margin-left: 38px;
          width: 830px;
          height: 565px;
        }
        .text-box-left {
          margin-left: 130px;
          width: 830px;
          height: 565px;
        }
        .detail-title {
          margin-top: 32px;
          width: 830px;
          height: 56px;
          text-align: center;
          font-size: 40px;
          font-weight: 400;
          color: #4d4d4d;
        }
        .detail-text {
          margin-top: 32px;
          width: 830px;
          height: 445px;
          text-indent: 2em;
          word-break: break-all;
          display: -webkit-box; /**对象作为伸缩盒子模型展示**/
          -webkit-box-orient: vertical; /**设置或检索伸缩盒子对象的子元素的排列方式**/
          -webkit-line-clamp: 8; /**显示的行数**/
          overflow: hidden; /**隐藏超出的内容**/
          font-size: 20px;
          font-weight: 400;
          text-align: left;
          color: #666666;
        }
      }
    }
  }
}
:deep(.el-carousel__container),
my-carousel {
  height: 580px !important;
}
</style>
